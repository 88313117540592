<template>
  <b-form-group
    label="Servis Danışmanı"
    label-for="id_com_user"
  >
    <validation-provider
      #default="{ errors }"
      name="Servis Danışmanı"
      rules="required"
    >
      <v-select
        id="id_com_user"
        v-model="service.id_com_user"
        label="name"
        :reduce="item => item.id"
        :options="users"
        placeholder="Seçiniz"
        @input="getTimesData"
      >
        <template v-slot:option="option">
          <div>
            {{ option.name }}
          </div>
          <div>
            <small class="text-warning">{{ option.brand }}</small>
          </div>
          <div>
            <small class="text-muted">{{ option.location }}</small>
          </div>
        </template>
        <template v-slot:selected-option="option">
          <div>
            {{ option.name }} <span class="text-primary font-small-2">{{ option.brand }} {{ option.location }}</span>
          </div>
        </template>
      </v-select>
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>

<script>
import {
  BFormGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationProvider, localize } from 'vee-validate'
import { required } from '@validations'

export default {
  name: 'Users',
  components: {
    BFormGroup,
    vSelect,
    ValidationProvider,
  },
  data() {
    return {
      required,
    }
  },
  computed: {
    service() {
      return this.$store.getters['serviceTracing/getService']
    },
    users() {
      return this.$store.getters['users/getUsers']
    },
  },
  created() {
    this.getUsers()
    localize('tr')
  },
  methods: {
    getTimesData() {
      if (this.service.id_com_user && this.service.appointment_date) {
        this.$store.dispatch('serviceTracing/getTimes', {
          id_com_user: this.service.id_com_user,
          appointment_date: this.service.appointment_date,
        })
      } else {
        this.$store.commit('serviceTracing/SET_TIMES', {
          data: [],
          serviceData: [],
        })
      }
    },
    getUsers() {
      this.$store.dispatch('users/usersList', {
        select: [
          'com_user.id AS id',
          'com_user.name AS name',
          'com_brand.name AS brand',
          'com_location.title AS location',
        ],
        where: {
          'com_user.status': 1,
          'com_user.id_com_department': 3,
        },
        order_by: [
          'com_user.id_com_location,com_user.id_com_brand', 'ASC',
        ],
      })
    },
  },
}
</script>
